 const images = [
    {
            img:"Images/work_img_2.jpeg",
            Influencers:6,
            likes:"250k+",
            reach:"3.5M+",
            logo:"Images/sugar_logo.png",
            
          },
          {
            img:"Images/work_img_4.jpeg",
            Influencers:5,
            likes:"400k+",
            reach:"3.9M+",
            logo:"Images/code_logo.png",
            
          },
          
          {img:"Images/work_img_3.jpeg",
          Influencers:2,
          likes:"50k+",
          reach:"1.8M+",
          logo:"Images/TOI_logo.jpg",
          
      },
      {
        img:"Images/work_img_1.jpeg",
        Influencers:502,
        likes:"386k+",
        reach:"5.4M+",
        logo:"Images/secret .jpeg",
       
      },
    
        ];

  export const displayImageArray=[
    "Images/sugar (1).webp","Images/code_campaign.jpg","Images/secret_t_campaign.png","Images/code1.JPG"
  ]


        export default images;