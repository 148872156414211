import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../Styles/Buttons";
import image from "./bg-img.png";
import { useEffect, useState } from "react";
import InstagramCarousel from "./InstagramCarousel";
import { head } from "lodash";
const full_bg_img="Images/bg_img.png";
const HeroSection = () => {
  const headings = ["Branding", "Marketing", "Tech"];
  const [heading, setHeading] = useState("");
  let index = 1;
  let inc = 0;

  const showHeadings = () => {
    let newTitle = headings[inc].slice(0, index);

    setHeading(newTitle.toUpperCase());
   if (index > headings[inc].length) {
      if (inc !== headings.length - 1) {
        inc++;
      } else {
        inc = 0;
      }
      index = 1;
    } else {
      index++;
    }


    setTimeout(() => {
      showHeadings();
    }, 300);

  };

  useEffect(() => {
    showHeadings();
  }, []);

  return (
    <Wrapper>
      <div className="hero-section-div">
        <div
          className="first-div"
          style={{
            position: "absolute",
            top: "-2rem",
            color: "white",
          }}
        >
          <div className="innerFirstDiv">
            <p>We deliver unparalleled </p>
            <div className="changingdiv">
              <h1 className="left-heading" style={{marginLeft:heading.startsWith("T")?"-3%":"-8%"}}>{heading}</h1>
              <h1 className="right-heading" style={{marginLeft:heading.startsWith("T")?"4.5%":"15%"}}>SOLUTION</h1>
            </div>
            <p>redefine your business's digital presence</p>
            <p>
              Elevate Indian brands to global prominence, setting a benchmark in
              every sector that others aspire to reach.
            </p>
            <div className="buttons">
              <NavLink to="/contactus" >
                <Button>Brands</Button>
              </NavLink>
              <NavLink to="/creatorform">
                <Button style={{marginLeft:"1rem"}}>Creators</Button>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="second-div">
          <InstagramCarousel />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  overflow-x: hidden !important;

  p {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 1rem;
    z-index: 1;
  }
  .hero-section-div {
    display: flex;
    
    overflow-x: hidden !important;
    flex-direction: column;
  }

  .buttons{
    margin:auto;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:2rem;

    @media screen and (max-width:768px){
      width:100%;
          }
    @media screen and (max-width:1260px and min-width:800px){
      width:100%;
      
    }
  }

  .first-div {
    height: 105vh;
    width: 100vw;
    background: radial-gradient(circle at 0% 0%,  #442063,#593c8f);
    overflow-x: hidden !important;
    margin: 2rem 0;
    padding: 2.5rem;
    @media screen and (max-width: 1030px) {
      height:70vh;
    }
    @media screen and (max-width: 768px) {
      padding:1rem 1.5rem;
      height:80vh;
      // width:105vw;
    }
    .innerFirstDiv{
      margin:4rem auto;
      h1 {
        margin-left: 23rem;
        color: white;
        padding: 1.5rem;
        font-size: 3rem;
        word-spacing: 1rem;
        @media screen and (max-width: 768px) {
            font-size:1.25rem;
          }
        }
        p{
          @media screen and (max-width: 768px) {
            font-size:0.9rem;
          }
        }
      }
      .changingdiv {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        .left-heading{
          width:10%;
          margin-left:-9%;
          @media screen and (max-width: 768px) {
              margin-left:-2%;
        }
        @media screen and (max-width: 500px) {
          margin-left:-13%;
    }
      }
      .right-heading{
        @media screen and (min-width: 1300px) {
        // margin-left:13%;
      }
      @media screen and (max-width: 1260px) {
        margin-left:21.5%;
      }
      @media screen and (max-width: 768px) {
        margin-left:30%;
      }
    }
      }
    }
  }

  
  .second-div {
    z-index: 1;
    margin: 25rem 0 0 0;
    width: 90vw;
    height: 40vh;
  }
`;

export default HeroSection;
