import React from 'react'

const DefaultPage = () => {
  return (
    <div>
      <h2 style={{textAlign:"center",justifyContent:"center"}}>Page DoesNot Exist</h2>
    </div>
  )
}

export default DefaultPage