import React from 'react'
import styled from 'styled-components'
const Blogs = () => {
  return (
    <Wrapper>
      <h1>Blogs Updating Soon</h1>
    </Wrapper>
  )
}

const Wrapper=styled.section`
display:flex;
justify-content:center;
align-item:center;
text-align:center;
height:40vh;
padding:5%;
`;

export default Blogs
